<template>
  <figure v-lazyload>
    <img class="image__item" v-if="posterChange" :data-url="source" loading="lazy" alt />
    <img class="image__item" v-else :data-url="source" loading="lazy" alt />
  </figure>
</template>

<script>
import LazyLoad from "../../directives/lazyLoad.js";
import { eventBus } from "@/eventBus";

export default {
  name: "imageItem",
  props: {
    source: {
      type: String | null,
      required: true,
    },
  },
  data() {
    return {
      posterChange: true,
    };
  },
  watch: {
    source(val) {
      if (val) {
        this.posterChange = !this.posterChange;
      }
    },
  },
  directives: {
    lazyload: LazyLoad,
  },
};
</script>

<style lang="scss" scoped>
.image__wrapper {
  height: 100%;
  width: 100%;
}
.image__item {
  width: 100%;
  height: 100%;
  // &:focus {
  //   // outline: none;
  // }
}
</style>
